import "./App.css";
import SignupForm from "./components/SignupForm";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./page/HomePage";
import CouponPage from "./page/CouponPage";

function App() {
  return (
    <BrowserRouter>
      <div className="h-[100vh] flex flex-col justify-center items-center">
        <Routes>
          <Route path="/" exact element={<SignupForm />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/coupon" element={<CouponPage />} />
          {/* <Route path="/delete" element={<DeleteAccountPage />} /> */}
          {/* <Route component={NotFound} /> */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
