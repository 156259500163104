/* eslint-disable no-lone-blocks */
import React, {useState} from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import loaderGif from "./../assets/loader.gif";

import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import CouponTable from "../components/CouponTable";
import { FaDownLong } from "react-icons/fa6";
import { BiDownload } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";

// Register the required elements
ChartJS.register(ArcElement, Tooltip, Legend);


const CouponPage = () => {

  const location = useLocation();

  console.log("Data >>> ", location.state);

  const navigate = useNavigate();
  const [smartCoupon, setsmartCoupon] = useState([]);
  const [premiumCoupon, setpremiumCoupon] = useState([]);
  const [allCoupons, setAllCoupons] = useState({})
  const [dataStat, setDataStat] = useState({})

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [activeTab, setActiveTab] = useState("smart"); // Track the active tab
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const itemsPerPage = 20; // Coupons per page

  // Data for the doughnut chart
  // const chartData = {
  //   labels: ["Smart Coupons", "Premium Coupons" /*, "Other"*/],
  //   datasets: [
  //     {
  //       data: [smartCoupon.length, premiumCoupon.length/*, allCoupons?.totalRecords - (smartCoupon.length + premiumCoupon.length)*/],
  //       backgroundColor: ["#34D399", "#60A5FA"/*, "#808080"*/],
  //       hoverBackgroundColor: ["#059669", "#2563EB"/*,"#2a2a2a"*/],
  //     },
  //   ],
  // };


  const initializeData = ()=>{
    setLoading(true);
    // api 3
    axios.get('https://nodeapi.smartdietplanner.com/api/coupon/getCouponStats').then(res=>{
      console.log("getCouponStats:: ", res)
      setDataStat(res.data);
    }).catch(err=>{
      console.log("Error: ", err);
      alert(err);
    })

    setLoading(false);

    // api3 
    // axios.get(`https://nodeapi.smartdietplanner.com/api/coupon/getAllCouponsConsumed?page=${currentPage}`).then(res=>{
    //   console.log("All Coupon COnsumed:: ", res)
    //   setAllCoupons(res.data);
    // }).catch(err=>{
    //   console.log("Error: ", err);
    //   alert(err);
    // })

  }


  // Use useEffect to set up the interval
  React.useEffect(() => {
    if (location.state === null) {
      navigate("/");
      return;
    }
    
    initializeData();

  }, []);

  const handleLogout = () => {
    navigate("/", { replace: true });
  };

  // Get current page's data
  // const getPaginatedData = (coupons) => {
  //   const startIndex = (currentPage - 1) * itemsPerPage;
  //   const endIndex = startIndex + itemsPerPage;
  //   return coupons.slice(startIndex, endIndex);
  // };

  // Calculate total pages
  // const totalPages = Math.ceil(
  //   (activeTab === "smart" ? smartCoupon : premiumCoupon).length /
  //     itemsPerPage
  // );

  // Change page handler
  // const changePage = (page) => {
  //   if (page >= 1 && page <= totalPages) {
  //     setCurrentPage(page);
  //   }
  // };

  function downloadCSV(data, filename = "data.csv") {
    // Extract headers
    const headers = Object.keys(data[0]);
  
    // Build CSV content
    const csvContent = [
      headers.join(","), // Header row
      ...data.map(row =>
        headers.map(header => JSON.stringify(row[header] || "")).join(",")
      ) // Data rows
    ].join("\n");
  
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  
    // Create a link element to download the file
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
  
    // Append link to the DOM and trigger the download
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  // Example usage with the given data
  const data = [
    {
      _id: "fravivasm1386323m",
      couponNum: "fravivasm1386323m",
      enterpriseId: "AVIVA",
      amount: 100,
      duration: 365,
      consumed: false,
      _class: "com.sdp.mw.models.coupon.consume.CouponMasterDTO",
    },
    {
      _id: "fravivasm1222386j",
      couponNum: "fravivasm1222386j",
      enterpriseId: "AVIVA",
      amount: 100,
      duration: 365,
      consumed: false,
      _class: "com.sdp.mw.models.coupon.consume.CouponMasterDTO",
    },
  ];

  // Call the function to download the data as CSV
  const downloadClickListner = (type, consumed)=>{
    setLoading(true);
    var query = '';
    if(consumed){
      query = '?consumed=true'
    }
    // smart premium
    if(type ==="smart"){
      // API 1
    axios.get(`https://nodeapi.smartdietplanner.com/api/coupon/getSmartCoupons${query}`).then(res=>{
      console.log("Smart Coupon COnsumed:: ", res);
      // setsmartCoupon(res.data);
      downloadCSV(res.data, `Smart Coupon ${consumed ? 'Consumed': 'Unconsumed'}.csv`);
    }).catch(err=>{
      console.log("Error: ", err);
      alert(err);
    })
    
    }else {
      // api 2
    axios.get(`https://nodeapi.smartdietplanner.com/api/coupon/getPremiumCoupons${query}`).then(res=>{
      console.log("Premium Coupon COnsumed:: ", res)
      // setpremiumCoupon(res.data);
      downloadCSV(res.data, `Premium Coupon ${consumed ? 'Consumed': 'Unconsumed'}.csv`);
    }).catch(err=>{
      console.log("Error: ", err);
      alert(err);
    })
    }

    setLoading(false);
  }

  return (
    <div className="w-full h-full py-5 px-4">
      {/* Header Section */}
      <div className="flex justify-between">
        <img
          src="https://fitrofy.com/wp-content/uploads/2023/04/mainlogo.webp"
          alt="logo"
          className="h-12"
        />
        <div className="flex items-center justify-between w-[15%]">
          <p className="font-semibold text-xl">v0.5</p>
          <button
            className="border-2 bg-blue-500 py-2 px-4 rounded-full font-bold text-white"
            onClick={handleLogout}
          >
            Log out
          </button>
        </div>
      </div>
      <h2 className="text-xl font-semibold mt-5">
        Welcome {location.state?.companyName}!
      </h2>

      {/* Table Section */}
      {loading ? <img src={loaderGif} alt="loader" className="w-10 mx-auto" />: 
      <div className="overflow-x-auto bg-gray-100 p-6 rounded-md shadow-lg">
        <table
          className="w-[60%] bg-white shadow rounded-md table-fixed mx-auto"
          style={{ tableLayout: "fixed" }}
        >
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="py-3 px-4 text-left w-[40%]">Category</th>
              <th className="py-3 px-4 text-center w-[60%]">Numbers</th>
            </tr>
          </thead>
          <tbody>
            {/* Smart Coupon Consumed */}
            <tr className="hover:bg-gray-100">
              <th className="bg-blue-500 text-white py-3 px-4 text-left font-semibold border-t border-white">
                Smart Coupon Consumed
              </th>
        
              <td className="py-3 px-4 flex items-center justify-between">
                <span className="text-gray-700 font-medium">{dataStat?.smartCouponsConsumed} </span>
                <button
                  onClick={()=>downloadClickListner("smart", true)}
                  className="text-blue-500 hover:text-blue-700 flex items-center gap-1"
                  aria-label="Download consumed smart coupons"
                >
                  <BiDownload className="text-xl" /> Download
                </button>
              </td>
            </tr>

            {/* Smart Coupon Unconsumed */}
            <tr className="hover:bg-gray-100">
              <th className="bg-blue-500 text-white py-3 px-4 text-left font-semibold border-t border-white">
                Smart Coupon Unconsumed
              </th>
              <td className="py-3 px-4 flex items-center justify-between">
                <span className="text-gray-700 font-medium">{dataStat?.smartCouponsNotConsumed}</span>
                <button
                  onClick={()=>downloadClickListner("smart", false)}
                  className="text-blue-500 hover:text-blue-700 flex items-center gap-1"
                  aria-label="Download unconsumed smart coupons"
                >
                  <BiDownload className="text-xl" /> Download
                </button>
              </td>
            </tr>

            {/* Premium Coupon Consumed */}
            <tr className="hover:bg-gray-100">
              <th className="bg-red-500 text-white py-3 px-4 text-left font-semibold border-t border-white">
                Premium Coupon Consumed
              </th>
              <td className="py-3 px-4 flex items-center justify-between">
                <span className="text-gray-700 font-medium">{dataStat?.premiumCouponsConsumed}</span>
                <button
                  onClick={()=>downloadClickListner("premium", true)}
                  className="text-blue-500 hover:text-blue-700 flex items-center gap-1"
                  aria-label="Download consumed premium coupons"
                >
                  <BiDownload className="text-xl" /> Download
                </button>
              </td>
            </tr>
            {/* Premium Coupon Unconsumed */}
            <tr className="hover:bg-gray-100">
              <th className="bg-red-500 text-white py-3 px-4 text-left font-semibold border-t border-white">
                Premium Coupon Unconsumed
              </th>
              <td className="py-3 px-4 flex items-center justify-between">
                <span className="text-gray-700 font-medium">{dataStat?.premiumCouponsNotConsumed}</span>
                <button
                  onClick={()=>downloadClickListner("premium", false)}
                  className="text-blue-500 hover:text-blue-700 flex items-center gap-1"
                  aria-label="Download unconsumed premium coupons"
                >
                  <BiDownload className="text-xl" /> Download
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div> }
    </div>
  );

}

{/*      <div className="p-6 bg-gray-100">
        <h1 className="text-3xl font-bold text-center mb-6">Coupon Consumption</h1>

        {/* Chart Section 
        <div className="flex justify-center items-center mb-10">
          <div className="w-[35%] bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 text-center">Coupon Consumption Breakdown</h2>
            <Doughnut data={chartData} />
          </div>
        </div>
      </div>
     <div className="p-10 border-2 border-black mt-10 mx-10"> 
       <div className="p-6 bg-gray-100">
      <h1 className="text-3xl font-bold text-center mb-6">Coupon Dashboard</h1> */}

      {/* Tab Selector */}
      {/* <div className="flex justify-center space-x-4 mb-6">
        <button
          className={`px-6 py-2 rounded-full font-medium transition ${
            activeTab === "smart"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => {
            setActiveTab("smart");
            setCurrentPage(1); // Reset to first page
          }}
        >
          Smart Coupons
        </button>
        <button
          className={`px-6 py-2 rounded-full font-medium transition ${
            activeTab === "premium"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => {
            setActiveTab("premium");
            setCurrentPage(1); // Reset to first page
          }}
        >
          Premium Coupons
        </button>
      </div> */}

      {/* Coupon Lists */}
      {/* <div className="relative w-full  mx-auto">
        {/* Smart Coupons */}
        {/* {activeTab === "smart" && (
          <div> */}
            {/* <h2 className="text-xl font-semibold mb-4 text-center">
              Smart Coupons
            </h2> */}
            {/* <ul className="bg-white shadow-md rounded-lg p-4"> */}
              {/* {getPaginatedData(smartCoupon).map((coupon, index) => (
                <li
                  key={index}
                  className="p-2 border-b last:border-b-0 text-gray-700"
                >
                  {JSON.stringify(coupon)}
                </li>
              ))} */}
              {/* <CouponTable coupons={smartCoupon} name="Smart Coupon" />
            </ul>
          </div>
        )} */}

        {/* Premium Coupons */}
        {/* {activeTab === "premium" && (
          <div>
            {/* <h2 className="text-xl font-semibold mb-4 text-center"> 
              Premium Coupons
            </h2> 
            <ul className="bg-white shadow-md rounded-lg p-4">
              {/* {getPaginatedData(premiumCoupon).map((coupon, index) => (
                <li
                  key={index}
                  className="p-2 border-b last:border-b-0 text-gray-700"
                >
                  {JSON.stringify(coupon)}
                </li>
              ))} 
              <CouponTable coupons={premiumCoupon} name="Premium Coupon" />
            </ul>
          </div>
        )}
      </div> */}

      {/* Pagination Controls 
      // <div className="flex justify-center items-center mt-4">
      //   <button
      //     className={`px-4 py-2 mr-2 rounded ${
      //       currentPage === 1
      //         ? "bg-gray-300 cursor-not-allowed"
      //         : "bg-blue-500 text-white"
      //     }`}
      //     onClick={() => changePage(currentPage - 1)}
      //     disabled={currentPage === 1}
      //   >
      //     Prev
      //   </button>
      //   <span className="text-gray-700 font-medium">
      //     Page {currentPage} of {totalPages}
      //   </span>
      //   <button
      //     className={`px-4 py-2 ml-2 rounded ${
      //       currentPage === totalPages
      //         ? "bg-gray-300 cursor-not-allowed"
      //         : "bg-blue-500 text-white"
      //     }`}
      //     onClick={() => changePage(currentPage + 1)}
      //     disabled={currentPage === totalPages}
      //   >
      //     Next
      //   </button>
      // </div>
    // </div> 
       </div>
      
    </div>
  );
}; */}

export default CouponPage;
